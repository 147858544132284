import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import { Elements } from './components/workspace/Elements';
import { Parameters } from './components/workspace/Parameters';
import { Categories } from './components/workspace/Categories';
import { ValueTypes } from './components/workspace/ValueTypes';
import { ElementTypes } from './components/workspace/ElementTypes';
import { UnitGroups } from './components/workspace/UnitGroups';
import { Units } from './components/workspace/Units';
import { ClientInterfaces } from './components/workspace/ClientInterfaces';
import Application from './components/admin/Application';
import Users from './components/admin/Users';
import AdminStorages from './components/admin/Storages';
import AdminFactories from './components/admin/Factories';
import { loginRequest } from './helpers/authConfig';
import { InteractionType, InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { Container, Spinner, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { callAPI } from './helpers/api';
import { Role } from './helpers/enums';
import applicationActions from './actions/applicationActions';
import { NotFound } from './components/common/404NotFound';
import { Error } from './components/common/Error';
import { isAdmin } from './helpers/commonHelpers';
import Tool from './components/inspector/Tool';
import parse from 'html-react-parser';

import './custom.css';
//import './custom-theme.scss';

export function App() {
    const dispatch = useDispatch();
    let hasUserManuallyLoggedOut = sessionStorage.getItem("LoggedOutRuca") || false; //console.log('hasUserManuallyLoggedOut', hasUserManuallyLoggedOut);

    const { login, error } = useMsalAuthentication(InteractionType.Silent, loginRequest);
    const { instance, accounts, inProgress } = useMsal();
    const [loading, setIsLoading] = useState(!hasUserManuallyLoggedOut);
    const [userData, setUserData] = useState(null);
    const [serverSideError, setServerSideError] = useState(null);
    const [unauthorizedText, setUnauthorizedText] = useState(null);
    //console.log('inProgress', inProgress, instance);

    let user = useSelector(state => state.application.user);
    const isOwner = user && user.highestRole >= Role.OWNER;
    const isNotAuthorized = !user || (user && (!user.highestRole || user.highestRole === Role.NONE));
    
    const getApplicationData = async (account) => {
        let result = await callAPI('user/current', instance, account);
        var data = result.json;
        if (!result.errorMsg && data) {
            var user = data.user;
            var env = data.environment;
            setUnauthorizedText(data.unauthorizedText);
            setUserData(user);
            dispatch(applicationActions.setUser({ ...user, authUser: account }));
            dispatch(applicationActions.setEnvironment(env));
            // Let's also fetch the flags available for whole application
            let flagResult = await callAPI('flag', instance, account)
            var flagData = flagResult.json;
            if (!flagResult.errorMsg && flagData) {
                dispatch(applicationActions.setFlags(flagData));
            }
            setServerSideError(null);
        }
        else {
            setServerSideError(result.errorMsg);
        }
        //console.log('user', user);
        setIsLoading(false);
    }

    useEffect(() => {
        //console.log('error', error);
        if (error instanceof InteractionRequiredAuthError && !hasUserManuallyLoggedOut) {
            login(InteractionType.Redirect, loginRequest); //console.log('user needs to login');
        }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //console.log('inProgress, accounts, instance, userData', inProgress, accounts, instance, userData);
        if (inProgress === InteractionStatus.None && accounts.length > 0) {
            if (userData) {
                // Skip data refresh if already set - adjust logic for your specific use case
                return;
            }
            getApplicationData(accounts[0]); //console.log('accounts', accounts);
        }
        if (inProgress === InteractionStatus.HandleRedirect && accounts.length > 0) {
            setIsLoading(true);
            getApplicationData(accounts[0]); //console.log('accounts', accounts);
        }
    }, [inProgress, accounts, instance, userData]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading || inProgress === InteractionStatus.Login) {
        return (
            <div className="mt-4 d-flex justify-content-center"><Spinner color="secondary" /></div>
        )
    }
  
    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                {isNotAuthorized ? 
                    <Layout>
                        <Container>
                            <Row className="mt-5">
                                {serverSideError && <Error msg={serverSideError} /> }
                                {!serverSideError && parse(unauthorizedText)}
                            </Row>
                        </Container>
                    </Layout>
                    : 
                    <Layout>
                        <Routes>
                            <Route exact path='/' element={<Home msalInstance={instance} />} />
                            <Route exact path='/workspace' element={<Elements msalInstance={instance} /> } />
                            {/*<Route path='/workspace/items' element={<Items msalInstance={instance} /> } />*/}
                            <Route path='/workspace/elements' element={<Elements msalInstance={instance} /> } />
                            <Route path='/workspace/parameters' element={<Parameters msalInstance={instance} /> } />
                            <Route path='/workspace/categories' element={<Categories msalInstance={instance} /> } />
                            <Route path='/workspace/valueTypes' element={<ValueTypes msalInstance={instance} /> } />
                            <Route path='/workspace/elementTypes' element={<ElementTypes msalInstance={instance} /> } />
                            <Route path='/workspace/unitgroups' element={<UnitGroups msalInstance={instance} /> } />
                            <Route path='/workspace/units' element={<Units msalInstance={instance} /> } />
                            <Route path='/workspace/client' element={<ClientInterfaces msalInstance={instance} />} />
                            <Route path='/inspector' element={<Tool msalInstance={instance} />} />
                            <Route path='/inspector/tool' element={<Tool msalInstance={instance} />} />
                            {isAdmin(user) ?
                                <Route exact path='/admin' element={<Application msalInstance={instance} /> } /> :
                                <Route exact path='/admin' element={<AdminStorages msalInstance={instance} />} />
                            }
                            {isAdmin(user) &&
                                <>
                                <Route path='/admin/users' element={<Users msalInstance={instance} />} />
                                <Route path='/admin/application' element={<Application msalInstance={instance} />} />
                                </>
                            }
                            {isOwner &&
                                <React.Fragment>
                                    <Route path='/admin/storages' element={<AdminStorages msalInstance={instance}/> } />
                                    <Route path='/admin/factories' element={<AdminFactories msalInstance={instance} /> } />
                                </React.Fragment>
                            }
                            <Route path="*" element={<NotFound/> } />
                        </Routes>
                    </Layout>
                }
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Layout>
                    <Container>
                        <Row className="mt-5">
                            Please login to access RUCA application.
                        </Row>
                    </Container>
                </Layout>
            </UnauthenticatedTemplate>
        </React.Fragment>
    );
}
