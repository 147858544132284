import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { getCheckboxColumnDef, getMoveColumnDef } from '../../helpers/tableHelpers';
import {
    useReactTable,
    getCoreRowModel,
    flexRender
} from '@tanstack/react-table'
import {
    DndContext,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    closestCenter,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
    useSortable,
    arrayMove,
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Table } from 'reactstrap';
import { CSS } from '@dnd-kit/utilities';
//import { DragDropTest } from './DragDropTest';

// Row Component
const DraggableRow = ({ row, render }) => {
    const { transform, transition, setNodeRef, isDragging } = useSortable({
        id: row.original.uniqueId,
    })
    //console.log('row', row, isDragging);
    const style = {
        transform: CSS.Transform.toString(transform), //let dnd-kit do its thing
        transition: transition,
        opacity: isDragging ? 0.8 : 1,
        zIndex: isDragging ? 1 : 0,
        position: 'relative',
    }
    return (
        // connect row ref to dnd-kit, apply important styles 
        <tr ref={setNodeRef} style={style}>
            {row.getVisibleCells().map(cell => (
                <td key={cell.id} style={{ width: cell.column.getSize() }}>
                    {render(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    )
}

const CustomTableWithEditableCellsAndSelection = forwardRef(({
    tableColumns,
    tableData,
    onSelectedChanged,
    updateCellData,
    reOrder,
}, ref) => {
    //console.log('CustomTableWithEditableCells');
    const [data, setData] = useState(() => []);
    const [dataIds, setDataIds] = useState(() => []);
    const [columns] = useState(() => [getCheckboxColumnDef(), getMoveColumnDef(), ...tableColumns]);

    const [rowSelection, setRowSelection] = useState({});

    const table = useReactTable({
        data,
        columns,
        state: {
            rowSelection,
        },
        getRowId: row => row.uniqueId,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        meta: {
            updateCellData: (rowIndex, columnId, value) => {
                setData((old) => old.map((row, index) => {
                    if (index === rowIndex) {
                        return {
                            ...old[rowIndex],
                            [columnId]: value
                        };
                    }
                    return row;
                }));
                if (updateCellData) {
                    updateCellData(rowIndex, columnId, value);
                }
            }
        }
    });

    useImperativeHandle(ref, () => ({
        resetSelectedRows: () => table.toggleAllRowsSelected(false)
    }));

    useEffect(() => {
        //console.log('tableData', tableData);
        setData([...tableData]);
        setDataIds(tableData?.map(({ uniqueId }) => uniqueId))
    }, [tableData]);

    useEffect(() => {
        //console.log('row selection changed', rowSelection, table.getIsSomeRowsSelected(), table);
        if (onSelectedChanged) {
            const selectedIds = Object.keys(rowSelection).filter(key => rowSelection[key] === true).map(Number);
            if (selectedIds?.length > 0) {
                //console.log('table.getSelectedRowModel().rows', table.getSelectedRowModel().rows)
                onSelectedChanged(table.getSelectedRowModel().rows.map(r => r.original));
            }
            else {
                onSelectedChanged([]);
            }
        }
    }, [rowSelection]);// eslint-disable-line react-hooks/exhaustive-deps

    const sensors = useSensors(
        useSensor(MouseSensor, {}),
        useSensor(TouchSensor, {}),
        useSensor(KeyboardSensor, {})
    )

    // reorder rows after drag & drop
    function handleDragEnd(event) {
        const { active, over } = event; //console.log('event', event);
        if (active && over && active.id !== over.id) {
            setData(data => {
                const oldIndex = dataIds.indexOf(active.id);
                const newIndex = dataIds.indexOf(over.id);
                const reOrderedData = arrayMove(data, oldIndex, newIndex); //this is just a splice util
                //console.log('reOrderedData', reOrderedData);
                if (reOrder) {
                    reOrder(reOrderedData);
                }
                return reOrderedData;
            })
        }
    }
    
    //return (<DragDropTest tableColumns={tableColumns} tableData={tableData} />)
    //console.log('render table', dataIds, data); console.log('table.getRowModel().rows', table.getRowModel().rows);
    return (
        <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={handleDragEnd}
            sensors={sensors}
        >
            <Table size="sm" hover responsive bordered className="table-editable-cells mt-3">
                <thead className="bg-light">
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id} colSpan={header.colSpan}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    <SortableContext
                        items={dataIds}
                        strategy={verticalListSortingStrategy}
                    >
                        {table.getRowModel().rows.map(row => (
                            <DraggableRow key={row.original.uniqueId} row={row} render={flexRender} />
                        ))}
                    </SortableContext>
                </tbody>
            </Table>
                {/*<pre>{JSON.stringify(data, null, 2)}</pre>*/}
        </DndContext>
    );
});

export default CustomTableWithEditableCellsAndSelection;