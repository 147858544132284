import React, { useState } from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { IconType, Role, TextType } from '../../helpers/enums';
import ListHeader from '../common/ListHeader';
import AdminLayout from './AdminLayout';
//import { CustomRichTextEditor } from '../common/CustomEditor';
//import { default as CustomRichTextEditor } from '../common/CustomRichTextEditor/Editor';
//import { useForm, FormProvider } from "react-hook-form";
//import { Container, Row, Form, Col, ButtonToolbar, Button } from 'reactstrap';


//import { useSelector } from 'react-redux';
//import { callAPI } from '../../helpers/api';
import ApplicationText from './ApplicationText';

function Application({ msalInstance }) {
    const [activeMenuItem, setActiveMenuItem] = useState(1);
    const title = "Text management";
    const icon = IconType.APPLICATION;

    //const [content, setContent] = useState(null);
    //let currentUser = useSelector(state => state.application.user);

    //const methods = useForm();

    const setActive = (item) => {
        //console.log('Selected item', item);
        setActiveMenuItem(item);
    }

    //const onSubmit = async () => {
    //    console.log('Submitted!', content);
    //    let result = await callAPI('application', msalInstance,
    //        currentUser ? currentUser.authUser : {}, { text: content, textType: 0 });
    //    if (result.errorMsg && !result.json) {
    //        console.log(result.errorMsg);
    //    }
    //    else {
    //        setContent()
    //    }
    //}

    //const onChange = (html) => {
    //    setContent(html);
    //    console.log('Did we get the html: ', html);
    //}

    const sideMenu = <React.Fragment>
        <ListHeader
            title={title}
            showButtons={false}
            icon={icon}>
        </ListHeader>
        <ListGroup className="m-2 ms-3 me-3 me-lg-0">
            <ListGroupItem
                key="side_menu_item_1"
                action
                tag="button"
                className={activeMenuItem === 1 ? "border-0 active" : "border-0"}
                onClick={() => setActive(1)}
            >
                <ListGroupItemHeading className="mb-0">Intro page</ListGroupItemHeading>
                <ListGroupItemText className="mb-0">Edit home page content</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem
                key="side_menu_item_2"
                action
                tag="button"
                className={activeMenuItem === 2 ? "border-0 active" : "border-0"}
                onClick={() => setActive(2)}
            >
                <ListGroupItemHeading className="mb-0">Unauthorized page</ListGroupItemHeading>
                <ListGroupItemText className="mb-0">Edit unauthorized page content</ListGroupItemText>
            </ListGroupItem>
        </ListGroup>
    </React.Fragment>

                //<Container>
                //    <Row>
                //    <Col sm="12" lg="10" >
                //        <FormProvider {...methods}>
                //            <Form onSubmit={methods.handleSubmit(onSubmit)}>
                //                    <div
                //                        //className="container-editor editor-lg form-control mt-3"
                //                    >
                //                    {/*<Controller name="request"*/}
                //                    {/*    control={methods.control}*/}
                //                    {/*    defaultValue=""*/}
                //                    {/*    render={({ field }) =>*/}
                //                    {/*        <CustomRichTextEditor {...field}*/}
                //                    {/*            value=""*/}
                //                    {/*            //onChange={q => handleRequestChange(q)}*/}
                //                    {/*            //onBlur={validateRequest}*/}
                //                    {/*        />*/}
                //                    {/*    }*/}
                //                    {/*/>*/}
                //                        <CustomRichTextEditor onChange={onChange} value={content} />
                //                    {/*<div>Testing</div>*/}
                //                </div>
                //                {/*{validationError && <p className="text-danger">{validationError}</p>}*/}
                //                {/*{loading ? <CustomSpinner margin={true} /> :*/}
                //                    <ButtonToolbar className="mt-3 mb-5">
                //                        <Button
                //                            color="primary"
                //                            type="submit"
                //                            className="me-1"
                //                            //disabled={validationError?.length > 0}
                //                        >Save
                //                        </Button>
                //                        {/*<Button color="primary" onClick={resetJSON}>Clear</Button>*/}
                //                    </ButtonToolbar>
                //                {/*}*/}
                //            </Form>
                //        </FormProvider>
                //    </Col>
                //</Row>
                //</Container>

    return (
        <AdminLayout
            title="Manage application texts"
            sideMenu={sideMenu}
            requiredRole={Role.Admin}
        >
            {activeMenuItem === 1 ?
                <ApplicationText
                    msalInstance={msalInstance}
                    textType={TextType.MAIN} />
                :
                <ApplicationText
                    msalInstance={msalInstance}
                    textType={TextType.UNAUTHORIZED} />
            }
        </AdminLayout>
    );
}

export default Application;