import { Container, Row, Col } from 'reactstrap';
import { IconType } from '../../helpers/enums';

function ListHeader(props){
    //console.log('props', props);

    const Title = ({title, icon, className}) => {
        return(
            <h2 className={className ?? ""}>
                { icon && icon === IconType.FOLDER &&
                    <img src="./icons/sidebar_storage.svg" className="icon icon-sidebar-header me-2" alt="" />
                }
                { icon && icon === IconType.BUILDING &&
                    <img src="./icons/sidebar_factory.svg" className="icon icon-sidebar-header me-2" alt="" />
                }
                { icon && icon === IconType.PUZZLE &&
                    <img src="./icons/sidebar_element.svg" className="icon icon-sidebar-header me-2" alt="" />
                }
                { icon && icon === IconType.GROUP &&
                    <img src="./icons/sidebar_group.svg" className="icon icon-sidebar-header me-2" alt="" />
                }
                {icon && icon === IconType.USER &&
                    <img src="./icons/sidebar_user.svg" className="icon icon-sidebar-header me-2" alt="" />
                }
                {icon && icon === IconType.TEST &&
                    <img src="./icons/sidebar_test.svg" className="icon icon-sidebar-header me-2" alt="" />
                }
                {icon && icon === IconType.APPLICATION &&
                    <img src="./icons/sidebar_application.svg" className="icon icon-sidebar-header me-2" alt="" />
                }
                {title}
            </h2>
        );
    }

    if (!props.showButtons)
    {
        return (
            <Container fluid={true} className="ps-4">
                <Title title={props.title} icon={props.icon} />
            </Container>
        );
    }

    return (
        <Container fluid={true} className="ps-4">
            <Row>
                <Col className="d-flex justify-content-between pe-3 pe-lg-0">
                    <Title title={props.title} icon={props.icon} className="mt-3 text-nowrap" />
                    {props.children}
                </Col>
            </Row>
        </Container>
    );
}

export default ListHeader;