import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, NavLink, NavbarText, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { loginRequest } from "../helpers/authConfig";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import applicationActions from '../actions/applicationActions';
import { useDispatch, useSelector } from 'react-redux';
import { Role } from '../helpers/enums';
import { isAdmin } from '../helpers/commonHelpers';
import workspaceActions from '../actions/workspaceActions';
import { CustomNavLink } from '../components/common/CustomNavLink';

export function NavMenu() {
	const dispatch = useDispatch();
	const { instance } = useMsal();

	const [collapsed, setCollapsed] = useState(true);

	let currentUser = useSelector(state => state.application.user);
	const hasAccessToWorkspace = currentUser.highestRole >= Role.READER;
	const hasAccessToAdmin = currentUser.highestRole >= Role.OWNER;
	const hasAccessToHelp = currentUser.highestRole >= Role.READER;
	
	let userStr = currentUser ? currentUser.name : "No user";
	if (isAdmin(currentUser)) {
		userStr += " [admin]";
	}

	function CustomNavLinkWithAction({
		to,
		title,
		baseRoute
	}) {
		return (
			<CustomNavLink
				to={to}
				title={title}
				baseRoute={baseRoute}
				dispatchFunction={workspaceActions.setSelectedItems}
			/>
		)
	}

	function toggleNavbar() {
		setCollapsed(!collapsed);
	}

	function logoutUser() {
		sessionStorage.setItem("LoggedOutRuca", true);
		dispatch(applicationActions.setUser({}));
		instance.logoutRedirect({
			postLogoutRedirectUri: "/",
		});
	}

	function loginUser() {
		instance.loginRedirect(loginRequest).catch(e => {
			console.log(e);
		});
	}

	return (
		<React.Fragment>
			<AuthenticatedTemplate>
                <header>
					<Navbar className="navbar-toggleable-sm ng-white bg-light" light expand="md" color="faded">
						<NavbarBrand tag={RRNavLink} to="/">
							<img className="logo" src="brand_logo.svg" alt="Brand logo" />RUCA
						</NavbarBrand>
						<NavbarToggler onClick={toggleNavbar} className="mr-2" />
						<Collapse isOpen={!collapsed} navbar>
							<Nav className="me-auto" navbar>
								{hasAccessToWorkspace &&
									<React.Fragment>
										<CustomNavLinkWithAction to="/workspace/elements" title="Workspace" baseRoute="workspace" />
										<CustomNavLinkWithAction to="/inspector/tool" title="Inspector" baseRoute="inspector" />
									</React.Fragment>
								}
								{hasAccessToAdmin && isAdmin(currentUser) &&
									<CustomNavLinkWithAction to="/admin" title="Admin" baseRoute="admin" />}
								{hasAccessToAdmin && !isAdmin(currentUser) &&
									<CustomNavLinkWithAction to="/admin/storages" title="Admin" baseRoute="admin" />
								}
								{hasAccessToHelp &&
									<NavItem>
										<NavLink
											target="_blank"
											href="https://abb.sharepoint.com/:p:/r/sites/RMMG_LMG/Sales_tools_and_processes/Accessory%20maintanance%20tool/RucaUserGuide.pptx?d=w73a9ed9d3e4e40f3a6d0be7752ea33ff&csf=1&web=1&e=TDJfL1"
										>
											Help
										</NavLink>
									</NavItem>
								}
							</Nav>
							<Nav>
								<img src="./icons/navbar_user.svg" className="nav-icon nav-user" alt=""/>
								<UncontrolledDropdown nav>
									<DropdownToggle nav caret>
										{userStr}
									</DropdownToggle>
									<DropdownMenu end>
										<DropdownItem onClick={() => logoutUser()}>Log out</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</Nav>
						</Collapse>
					</Navbar>
				</header>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <header>
					<Navbar className="navbar-toggleable-sm ng-white bg-light" light expand="md" color="faded">
						<NavbarBrand tag={RRNavLink} to="/" className="me-auto">
							<img className="logo" src="brand_logo.svg" alt="Brand logo"/>RUCA
						</NavbarBrand>
						<NavbarToggler onClick={toggleNavbar} />
						<Collapse isOpen={!collapsed} navbar>
						<Nav className="me-auto" navbar>
						</Nav>
							<NavbarText><img src="./icons/navbar_user.svg" className="nav-icon nav-user" alt=""/></NavbarText>
							<Button onClick={() => loginUser()} className="Button me-1 bg-light">
								Login
							</Button>
						</Collapse>
					</Navbar>
				</header>
            </UnauthenticatedTemplate>
		</React.Fragment>
	);
}
