import React, { useState, useEffect } from 'react';
import { Container, Row, Form, Col, ButtonToolbar, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Editor as CustomRichTextEditor } from '../common/CustomRichTextEditor/Editor';
import { callAPI } from '../../helpers/api';
import { Error } from '../common/Error';
import { CustomSpinner } from '../common/CustomSpinner';
import { Info } from '../common/Info';
export default function ApplicationText({
    msalInstance,
    textType
}) {
    //console.log('textType', textType);
    let currentUser = useSelector(state => state.application.user);

    const [defaultValue, setDefaultValue] = useState(null);
    const [textId, setTextId] = useState(0);
    const [content, setContent] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [msg, setMsg] = useState(null);

    const populate = async () => {
        setIsLoading(true);
        setError(null);
        let result = await callAPI('application/' + textType, msalInstance, currentUser.authUser); //console.log('result', result);
        if (result.errorMsg) {
            setError(result.errorMsg);
            setTextId(0);
            setDefaultValue(null);
            setContent(null);
            setMsg(null);
        }
        else {
            setTextId(result.json?.id ?? 0);
            setDefaultValue(result.json?.text);
            setContent(result.json?.text);
        }
        setIsLoading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var user = currentUser ? currentUser.authUser : {};
        // Updating an existing one or creating a new one?
        let result = textId > 0 ?
            await callAPI('application/' + textId, msalInstance, user, content, "PUT") : 
            await callAPI('application', msalInstance, user, { text: content, textType: textType }, "POST");
        if (result.errorMsg && !result.json) {
            setError(result.errorMsg);
        }
        else {
            setContent(result.json);
            // Show the message for 3000 milliseconds
            setMsg("Text saved succesfully!");
            setTimeout(() => setMsg(""), 3000);
        }
    }

    const onChange = (html) => {
        setContent(html);
    }

    useEffect(() => {
        //console.log('initialHtmlFetched & textType & loading', initialHtmlFetched, textType, loading);
        if (textType > 0 && !loading) {
            //console.log("Let's fetch content ", textType);
            populate();
        }
    }, [textType]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <Container>
            <Row>
                <Col sm="12" lg="10" >
                    {loading && <CustomSpinner />}
                    {!loading && error && <Error msg={error} />} 
                    {!loading && !error && 
                        <Form onSubmit={handleSubmit}>
                            <div
                            //className="container-editor editor-lg form-control mt-3"
                            >
                                <CustomRichTextEditor
                                    onChange={onChange}
                                    defaultValue={defaultValue}
                                />
                            </div>
                            <ButtonToolbar className="mt-3 mb-1">
                                <Button
                                    color="primary"
                                    type="submit"
                                    className="me-1"
                                >Save
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    }
                </Col>
            </Row>
            {msg && <Row>
                <Col sm="12" md="6" lg="6" xl="3">
                    <Info msg={msg} />
                </Col>
            </Row>}
        </Container>
    )
}