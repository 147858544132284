import React, { useState, useEffect, useCallback } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $isCodeNode,
    CODE_LANGUAGE_MAP,
} from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
    $createParagraphNode,
    $getNodeByKey,
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    $isTextNode,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    COMMAND_PRIORITY_NORMAL,
    FORMAT_TEXT_COMMAND,
    KEY_MODIFIER_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND,
} from 'lexical';
import {
    $isParentElementRTL,
    $patchStyleText,
    $setBlocksType,
} from '@lexical/selection';
import {
    $createHeadingNode,
    $createQuoteNode,
    $isHeadingNode,
    $isQuoteNode,
} from '@lexical/rich-text';
import {
    $isListNode,
    INSERT_CHECK_LIST_COMMAND,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
} from '@lexical/list';
import {
    $findMatchingParent,
    $getNearestBlockElementAncestorOrThrow,
    $getNearestNodeOfType,
    $isEditorIsNestedEditor,
    mergeRegister,
} from '@lexical/utils';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { $isTableNode, $isTableSelection } from '@lexical/table';
import DropDown, { DropDownItem } from './ui/DropDown';
import { sanitizeUrl, getSelectedNode } from './utils';

const blockTypeToBlockName = {
    bullet: 'Bulleted List',
    check: 'Check List',
    code: 'Code Block',
    h1: 'Heading 1',
    h2: 'Heading 2',
    h3: 'Heading 3',
    h4: 'Heading 4',
    h5: 'Heading 5',
    h6: 'Heading 6',
    number: 'Numbered List',
    paragraph: 'Normal',
    quote: 'Quote',
};

function Divider() {
    return <div className="divider" />;
}

function dropDownActiveClass(active) {
    if (active) {
        return 'active dropdown-item-active';
    } else {
        return '';
    }
}

function BlockFormatDropDown({
    editor,
    blockType,
    rootType,
    disabled = false,
}){
    const formatParagraph = () => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
                $setBlocksType(selection, () => $createParagraphNode());
            }
        });
    };

    const formatHeading = (headingSize) => {
        console.log('headingSize', headingSize);
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createHeadingNode(headingSize));
            });
        }
    };

    const formatBulletList = () => {
        console.log('Make a bullet list', blockType);
        if (blockType !== 'bullet') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatCheckList = () => {
        if (blockType !== 'check') {
            editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatNumberedList = () => {
        if (blockType !== 'number') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        } else {
            formatParagraph();
        }
    };

    const formatQuote = () => {
        if (blockType !== 'quote') {
            editor.update(() => {
                const selection = $getSelection();
                $setBlocksType(selection, () => $createQuoteNode());
            });
        }
    };

    //const formatCode = () => {
    //    if (blockType !== 'code') {
    //        editor.update(() => {
    //            let selection = $getSelection();

    //            if (selection !== null) {
    //                if (selection.isCollapsed()) {
    //                    $setBlocksType(selection, () => $createCodeNode());
    //                } else {
    //                    const textContent = selection.getTextContent();
    //                    const codeNode = $createCodeNode();
    //                    selection.insertNodes([codeNode]);
    //                    selection = $getSelection();
    //                    if ($isRangeSelection(selection)) {
    //                        selection.insertRawText(textContent);
    //                    }
    //                }
    //            }
    //        });
    //    }
    //};

    return (
        <DropDown
            disabled={disabled}
            buttonClassName="toolbar-item block-controls"
            buttonIconClassName={'icon block-type ' + blockType}
            buttonLabel={blockTypeToBlockName[blockType]}
            buttonAriaLabel="Formatting options for text style">
            <DropDownItem
                className={'item ' + dropDownActiveClass(blockType === 'paragraph')}
                onClick={formatParagraph}>
                <i className="icon paragraph" />
                <span className="text">Normal</span>
            </DropDownItem>
            <DropDownItem
                className={'item ' + dropDownActiveClass(blockType === 'h1')}
                onClick={() => formatHeading('h1')}>
                <i className="icon h1" />
                <span className="text">Heading 1</span>
            </DropDownItem>
            <DropDownItem
                className={'item ' + dropDownActiveClass(blockType === 'h2')}
                onClick={() => formatHeading('h2')}>
                <i className="icon h2" />
                <span className="text">Heading 2</span>
            </DropDownItem>
            <DropDownItem
                className={'item ' + dropDownActiveClass(blockType === 'h3')}
                onClick={() => formatHeading('h3')}>
                <i className="icon h3" />
                <span className="text">Heading 3</span>
            </DropDownItem>
            <DropDownItem
                className={'item ' + dropDownActiveClass(blockType === 'bullet')}
                onClick={formatBulletList}>
                <i className="icon bullet-list" />
                <span className="text">Bullet List</span>
            </DropDownItem>
            <DropDownItem
                className={'item ' + dropDownActiveClass(blockType === 'number')}
                onClick={formatNumberedList}>
                <i className="icon numbered-list" />
                <span className="text">Numbered List</span>
            </DropDownItem>
            <DropDownItem
                className={'item ' + dropDownActiveClass(blockType === 'check')}
                onClick={formatCheckList}>
                <i className="icon check-list" />
                <span className="text">Check List</span>
            </DropDownItem>
            <DropDownItem
                className={'item ' + dropDownActiveClass(blockType === 'quote')}
                onClick={formatQuote}>
                <i className="icon quote" />
                <span className="text">Quote</span>
            </DropDownItem>
            {/*<DropDownItem*/}
            {/*    className={'item ' + dropDownActiveClass(blockType === 'code')}*/}
            {/*    onClick={formatCode}>*/}
            {/*    <i className="icon code" />*/}
            {/*    <span className="text">Code Block</span>*/}
            {/*</DropDownItem>*/}
        </DropDown>
    );
}

export default function Toolbar({ setIsLinkEditMode }) {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [blockType, setBlockType] = useState('paragraph');
    const [rootType, setRootType] = useState('root');
    const [selectedElementKey, setSelectedElementKey] = useState(null,);
    const [elementFormat, setElementFormat] = useState('left');
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [isCode, setIsCode] = useState(false);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    //const [modal, showModal] = useModal();
    const [isRTL, setIsRTL] = useState(false);
    const [codeLanguage, setCodeLanguage] = useState('');
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());
    const [isImageCaption, setIsImageCaption] = useState(false);

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            if (activeEditor !== editor && $isEditorIsNestedEditor(activeEditor)) {
                const rootElement = activeEditor.getRootElement();
                setIsImageCaption(
                    !!rootElement?.parentElement?.classList.contains(
                        'image-caption-container',
                    ),
                );
            } else {
                setIsImageCaption(false);
            }

            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === 'root'
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                        const parent = e.getParent();
                        return parent !== null && $isRootOrShadowRoot(parent);
                    });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);

            setIsRTL($isParentElementRTL(selection));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            const tableNode = $findMatchingParent(node, $isTableNode);
            if ($isTableNode(tableNode)) {
                setRootType('table');
            } else {
                setRootType('root');
            }

            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(
                        anchorNode,
                        ListNode,
          );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type);
                    }
                    if ($isCodeNode(element)) {
                        const language =
                            element.getLanguage();
                        setCodeLanguage(
                            language ? CODE_LANGUAGE_MAP[language] || language : '',
                        );
                        return;
                    }
                }
            }
            // Handle buttons
            let matchingParent;
            if ($isLinkNode(parent)) {
                // If node is a link, we need to fetch the parent paragraph node to set format
                matchingParent = $findMatchingParent(
                    node,
                    (parentNode) => $isElementNode(parentNode) && !parentNode.isInline(),
                );
            }

            // If matchingParent is a valid node, pass it's format type
            setElementFormat(
                $isElementNode(matchingParent)
                    ? matchingParent.getFormatType()
                    : $isElementNode(node)
                        ? node.getFormatType()
                        : parent?.getFormatType() || 'left',
            );
        }
        if ($isRangeSelection(selection) || $isTableSelection(selection)) {
            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));
            setIsStrikethrough(selection.hasFormat('strikethrough'));
            setIsSubscript(selection.hasFormat('subscript'));
            setIsSuperscript(selection.hasFormat('superscript'));
            setIsCode(selection.hasFormat('code'));
        }
    }, [activeEditor, editor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                setActiveEditor(newEditor);
                $updateToolbar();
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        activeEditor.getEditorState().read(() => {
            $updateToolbar();
        });
    }, [activeEditor, $updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
            activeEditor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL,
      ),
            activeEditor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL,
      ),
        );
    }, [$updateToolbar, activeEditor, editor]);

    useEffect(() => {
        return activeEditor.registerCommand(
            KEY_MODIFIER_COMMAND,
            (payload) => {
                const event = payload;
                const { code, ctrlKey, metaKey } = event;

                if (code === 'KeyK' && (ctrlKey || metaKey)) {
                    event.preventDefault();
                    let url;
                    if (!isLink) {
                        setIsLinkEditMode(true);
                        url = sanitizeUrl('https://');
                    } else {
                        setIsLinkEditMode(false);
                        url = null;
                    }
                    return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
                }
                return false;
            },
            COMMAND_PRIORITY_NORMAL,
        );
    }, [activeEditor, isLink, setIsLinkEditMode]);

    const applyStyleText = useCallback(
        (styles, skipHistoryStack) => {
            activeEditor.update(
                () => {
                    const selection = $getSelection();
                    if (selection !== null) {
                        $patchStyleText(selection, styles);
                    }
                },
                skipHistoryStack ? { tag: 'historic' } : {},
            );
        },
        [activeEditor],
    );

    const clearFormatting = useCallback(() => {
        activeEditor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection) || $isTableSelection(selection)) {
                const anchor = selection.anchor;
                const focus = selection.focus;
                const nodes = selection.getNodes();
                const extractedNodes = selection.extract();

                if (anchor.key === focus.key && anchor.offset === focus.offset) {
                    return;
                }

                nodes.forEach((node, idx) => {
                    // We split the first and last node by the selection
                    // So that we don't format unselected text inside those nodes
                    if ($isTextNode(node)) {
                        // Use a separate variable to ensure TS does not lose the refinement
                        let textNode = node;
                        if (idx === 0 && anchor.offset !== 0) {
                            textNode = textNode.splitText(anchor.offset)[1] || textNode;
                        }
                        if (idx === nodes.length - 1) {
                            textNode = textNode.splitText(focus.offset)[0] || textNode;
                        }
                        /**
                         * If the selected text has one format applied
                         * selecting a portion of the text, could
                         * clear the format to the wrong portion of the text.
                         *
                         * The cleared text is based on the length of the selected text.
                         */
                        // We need this in case the selected text only has one format
                        const extractedTextNode = extractedNodes[0];
                        if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
                            textNode = extractedTextNode;
                        }

                        if (textNode.__style !== '') {
                            textNode.setStyle('');
                        }
                        if (textNode.__format !== 0) {
                            textNode.setFormat(0);
                            $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
                        }
                        node = textNode;
                    } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
                        node.replace($createParagraphNode(), true);
                    } else if ($isDecoratorBlockNode(node)) {
                        node.setFormat('');
                    }
                });
            }
        });
    }, [activeEditor]);

    const insertLink = useCallback(() => {
        if (!isLink) {
            setIsLinkEditMode(true);
            activeEditor.dispatchCommand(
                TOGGLE_LINK_COMMAND,
                sanitizeUrl('https://'),
            );
        } else {
            setIsLinkEditMode(false);
            activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [activeEditor, isLink, setIsLinkEditMode]);

    const onCodeLanguageSelect = useCallback(
        (value) => {
            activeEditor.update(() => {
                if (selectedElementKey !== null) {
                    const node = $getNodeByKey(selectedElementKey);
                    if ($isCodeNode(node)) {
                        node.setLanguage(value);
                    }
                }
            });
        },
        [activeEditor, selectedElementKey],
    );
    //const insertGifOnClick = (payload) => {
    //    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    //};

    const canViewerSeeInsertDropdown = !isImageCaption;
    const canViewerSeeInsertCodeButton = !isImageCaption;

    return (
        <div className="toolbar">
            <button
                disabled={!canUndo || !isEditable}
                onClick={() => {
                    activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
                }}
                title={'Undo (Ctrl+Z)'}
                type="button"
                className="toolbar-item spaced"
                aria-label="Undo">
                <i className="format undo" />
            </button>
            <button
                disabled={!canRedo || !isEditable}
                onClick={() => {
                    activeEditor.dispatchCommand(REDO_COMMAND, undefined);
                }}
                title={'Redo (Ctrl+Y)'}
                type="button"
                className="toolbar-item"
                aria-label="Redo">
                <i className="format redo" />
            </button>
            <Divider />
            {blockType in blockTypeToBlockName && activeEditor === editor && (
                <>
                    <BlockFormatDropDown
                        disabled={!isEditable}
                        blockType={blockType}
                        rootType={rootType}
                        editor={activeEditor}
                    />
                    <Divider />
                </>
            )}
            <button
                disabled={!isEditable}
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                }}
                className={'toolbar-item spaced ' + (isBold ? 'active' : '')}
                title={'Bold (Ctrl+B)'}
                type="button"
                aria-label={`Format text as bold. Shortcut: ${'Ctrl+B'}`}>
                <i className="format bold" />
            </button>
            <button
                disabled={!isEditable}
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                }}
                className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}
                title={'Italic (Ctrl+I)'}
                type="button"
                aria-label={`Format text as italics. Shortcut: $'Ctrl+I'}`}>
                <i className="format italic" />
            </button>
            <button
                disabled={!isEditable}
                onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                }}
                className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
                title={'Underline (Ctrl+U)'}
                type="button"
                aria-label={`Format text to underlined. Shortcut: ${'Ctrl+U'}`}>
                <i className="format underline" />
            </button>
            <button
                disabled={!isEditable}
                onClick={insertLink}
                className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
                aria-label="Insert link"
                title="Insert link"
                type="button">
                <i className="format link" />
            </button>




            {/*{blockType === 'code' ? (*/}
            {/*    <DropDown*/}
            {/*        disabled={!isEditable}*/}
            {/*        buttonClassName="toolbar-item code-language"*/}
            {/*        buttonLabel={getLanguageFriendlyName(codeLanguage)}*/}
            {/*        buttonAriaLabel="Select language">*/}
            {/*        {CODE_LANGUAGE_OPTIONS.map(([value, name]) => {*/}
            {/*            return (*/}
            {/*                <DropDownItem*/}
            {/*                    className={`item ${dropDownActiveClass(*/}
            {/*                        value === codeLanguage,*/}
            {/*                    )}`}*/}
            {/*                    onClick={() => onCodeLanguageSelect(value)}*/}
            {/*                    key={value}>*/}
            {/*                    <span className="text">{name}</span>*/}
            {/*                </DropDownItem>*/}
            {/*            );*/}
            {/*        })}*/}
            {/*    </DropDown>*/}
            {/*) : (*/}
            {/*    <>*/}
            {/*        <FontDropDown*/}
            {/*            disabled={!isEditable}*/}
            {/*            style={'font-family'}*/}
            {/*            value={fontFamily}*/}
            {/*            editor={activeEditor}*/}
            {/*        />*/}
            {/*        <Divider />*/}
            {/*        <FontSize*/}
            {/*            selectionFontSize={fontSize.slice(0, -2)}*/}
            {/*            editor={activeEditor}*/}
            {/*            disabled={!isEditable}*/}
            {/*        />*/}
            {/*        <Divider />*/}
            {/*        <button*/}
            {/*            disabled={!isEditable}*/}
            {/*            onClick={() => {*/}
            {/*                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');*/}
            {/*            }}*/}
            {/*            className={'toolbar-item spaced ' + (isBold ? 'active' : '')}*/}
            {/*            title={IS_APPLE ? 'Bold (⌘B)' : 'Bold (Ctrl+B)'}*/}
            {/*            type="button"*/}
            {/*            aria-label={`Format text as bold. Shortcut: ${IS_APPLE ? '⌘B' : 'Ctrl+B'*/}
            {/*                }`}>*/}
            {/*            <i className="format bold" />*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            disabled={!isEditable}*/}
            {/*            onClick={() => {*/}
            {/*                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');*/}
            {/*            }}*/}
            {/*            className={'toolbar-item spaced ' + (isItalic ? 'active' : '')}*/}
            {/*            title={IS_APPLE ? 'Italic (⌘I)' : 'Italic (Ctrl+I)'}*/}
            {/*            type="button"*/}
            {/*            aria-label={`Format text as italics. Shortcut: ${IS_APPLE ? '⌘I' : 'Ctrl+I'*/}
            {/*                }`}>*/}
            {/*            <i className="format italic" />*/}
            {/*        </button>*/}
            {/*        <button*/}
            {/*            disabled={!isEditable}*/}
            {/*            onClick={() => {*/}
            {/*                activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');*/}
            {/*            }}*/}
            {/*            className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}*/}
            {/*            title={IS_APPLE ? 'Underline (⌘U)' : 'Underline (Ctrl+U)'}*/}
            {/*            type="button"*/}
            {/*            aria-label={`Format text to underlined. Shortcut: ${IS_APPLE ? '⌘U' : 'Ctrl+U'*/}
            {/*                }`}>*/}
            {/*            <i className="format underline" />*/}
            {/*        </button>*/}
            {/*        {canViewerSeeInsertCodeButton && (*/}
            {/*            <button*/}
            {/*                disabled={!isEditable}*/}
            {/*                onClick={() => {*/}
            {/*                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');*/}
            {/*                }}*/}
            {/*                className={'toolbar-item spaced ' + (isCode ? 'active' : '')}*/}
            {/*                title="Insert code block"*/}
            {/*                type="button"*/}
            {/*                aria-label="Insert code block">*/}
            {/*                <i className="format code" />*/}
            {/*            </button>*/}
            {/*        )}*/}
            {/*        <button*/}
            {/*            disabled={!isEditable}*/}
            {/*            onClick={insertLink}*/}
            {/*            className={'toolbar-item spaced ' + (isLink ? 'active' : '')}*/}
            {/*            aria-label="Insert link"*/}
            {/*            title="Insert link"*/}
            {/*            type="button">*/}
            {/*            <i className="format link" />*/}
            {/*        </button>*/}
            {/*        <DropdownColorPicker*/}
            {/*            disabled={!isEditable}*/}
            {/*            buttonClassName="toolbar-item color-picker"*/}
            {/*            buttonAriaLabel="Formatting text color"*/}
            {/*            buttonIconClassName="icon font-color"*/}
            {/*            color={fontColor}*/}
            {/*            onChange={onFontColorSelect}*/}
            {/*            title="text color"*/}
            {/*        />*/}
            {/*        <DropdownColorPicker*/}
            {/*            disabled={!isEditable}*/}
            {/*            buttonClassName="toolbar-item color-picker"*/}
            {/*            buttonAriaLabel="Formatting background color"*/}
            {/*            buttonIconClassName="icon bg-color"*/}
            {/*            color={bgColor}*/}
            {/*            onChange={onBgColorSelect}*/}
            {/*            title="bg color"*/}
            {/*        />*/}
            {/*        <DropDown*/}
            {/*            disabled={!isEditable}*/}
            {/*            buttonClassName="toolbar-item spaced"*/}
            {/*            buttonLabel=""*/}
            {/*            buttonAriaLabel="Formatting options for additional text styles"*/}
            {/*            buttonIconClassName="icon dropdown-more">*/}
            {/*            <DropDownItem*/}
            {/*                onClick={() => {*/}
            {/*                    activeEditor.dispatchCommand(*/}
            {/*                        FORMAT_TEXT_COMMAND,*/}
            {/*                        'strikethrough',*/}
            {/*                    );*/}
            {/*                }}*/}
            {/*                className={'item ' + dropDownActiveClass(isStrikethrough)}*/}
            {/*                title="Strikethrough"*/}
            {/*                aria-label="Format text with a strikethrough">*/}
            {/*                <i className="icon strikethrough" />*/}
            {/*                <span className="text">Strikethrough</span>*/}
            {/*            </DropDownItem>*/}
            {/*            <DropDownItem*/}
            {/*                onClick={() => {*/}
            {/*                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');*/}
            {/*                }}*/}
            {/*                className={'item ' + dropDownActiveClass(isSubscript)}*/}
            {/*                title="Subscript"*/}
            {/*                aria-label="Format text with a subscript">*/}
            {/*                <i className="icon subscript" />*/}
            {/*                <span className="text">Subscript</span>*/}
            {/*            </DropDownItem>*/}
            {/*            <DropDownItem*/}
            {/*                onClick={() => {*/}
            {/*                    activeEditor.dispatchCommand(*/}
            {/*                        FORMAT_TEXT_COMMAND,*/}
            {/*                        'superscript',*/}
            {/*                    );*/}
            {/*                }}*/}
            {/*                className={'item ' + dropDownActiveClass(isSuperscript)}*/}
            {/*                title="Superscript"*/}
            {/*                aria-label="Format text with a superscript">*/}
            {/*                <i className="icon superscript" />*/}
            {/*                <span className="text">Superscript</span>*/}
            {/*            </DropDownItem>*/}
            {/*            <DropDownItem*/}
            {/*                onClick={clearFormatting}*/}
            {/*                className="item"*/}
            {/*                title="Clear text formatting"*/}
            {/*                aria-label="Clear all text formatting">*/}
            {/*                <i className="icon clear" />*/}
            {/*                <span className="text">Clear Formatting</span>*/}
            {/*            </DropDownItem>*/}
            {/*        </DropDown>*/}
            {/*        {canViewerSeeInsertDropdown && (*/}
            {/*            <>*/}
            {/*                <Divider />*/}
            {/*                <DropDown*/}
            {/*                    disabled={!isEditable}*/}
            {/*                    buttonClassName="toolbar-item spaced"*/}
            {/*                    buttonLabel="Insert"*/}
            {/*                    buttonAriaLabel="Insert specialized editor node"*/}
            {/*                    buttonIconClassName="icon plus">*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            activeEditor.dispatchCommand(*/}
            {/*                                INSERT_HORIZONTAL_RULE_COMMAND,*/}
            {/*                                undefined,*/}
            {/*                            );*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon horizontal-rule" />*/}
            {/*                        <span className="text">Horizontal Rule</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            activeEditor.dispatchCommand(INSERT_PAGE_BREAK, undefined);*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon page-break" />*/}
            {/*                        <span className="text">Page Break</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            showModal('Insert Image', (onClose) => (*/}
            {/*                                <InsertImageDialog*/}
            {/*                                    activeEditor={activeEditor}*/}
            {/*                                    onClose={onClose}*/}
            {/*                                />*/}
            {/*                            ));*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon image" />*/}
            {/*                        <span className="text">Image</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            showModal('Insert Inline Image', (onClose) => (*/}
            {/*                                <InsertInlineImageDialog*/}
            {/*                                    activeEditor={activeEditor}*/}
            {/*                                    onClose={onClose}*/}
            {/*                                />*/}
            {/*                            ));*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon image" />*/}
            {/*                        <span className="text">Inline Image</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() =>*/}
            {/*                            insertGifOnClick({*/}
            {/*                                altText: 'Cat typing on a laptop',*/}
            {/*                                src: catTypingGif,*/}
            {/*                            })*/}
            {/*                        }*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon gif" />*/}
            {/*                        <span className="text">GIF</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            activeEditor.dispatchCommand(*/}
            {/*                                INSERT_EXCALIDRAW_COMMAND,*/}
            {/*                                undefined,*/}
            {/*                            );*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon diagram-2" />*/}
            {/*                        <span className="text">Excalidraw</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            showModal('Insert Table', (onClose) => (*/}
            {/*                                <InsertTableDialog*/}
            {/*                                    activeEditor={activeEditor}*/}
            {/*                                    onClose={onClose}*/}
            {/*                                />*/}
            {/*                            ));*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon table" />*/}
            {/*                        <span className="text">Table</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            showModal('Insert Poll', (onClose) => (*/}
            {/*                                <InsertPollDialog*/}
            {/*                                    activeEditor={activeEditor}*/}
            {/*                                    onClose={onClose}*/}
            {/*                                />*/}
            {/*                            ));*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon poll" />*/}
            {/*                        <span className="text">Poll</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            showModal('Insert Columns Layout', (onClose) => (*/}
            {/*                                <InsertLayoutDialog*/}
            {/*                                    activeEditor={activeEditor}*/}
            {/*                                    onClose={onClose}*/}
            {/*                                />*/}
            {/*                            ));*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon columns" />*/}
            {/*                        <span className="text">Columns Layout</span>*/}
            {/*                    </DropDownItem>*/}

            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            showModal('Insert Equation', (onClose) => (*/}
            {/*                                <InsertEquationDialog*/}
            {/*                                    activeEditor={activeEditor}*/}
            {/*                                    onClose={onClose}*/}
            {/*                                />*/}
            {/*                            ));*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon equation" />*/}
            {/*                        <span className="text">Equation</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            editor.update(() => {*/}
            {/*                                const root = $getRoot();*/}
            {/*                                const stickyNode = $createStickyNode(0, 0);*/}
            {/*                                root.append(stickyNode);*/}
            {/*                            });*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon sticky" />*/}
            {/*                        <span className="text">Sticky Note</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    <DropDownItem*/}
            {/*                        onClick={() => {*/}
            {/*                            editor.dispatchCommand(*/}
            {/*                                INSERT_COLLAPSIBLE_COMMAND,*/}
            {/*                                undefined,*/}
            {/*                            );*/}
            {/*                        }}*/}
            {/*                        className="item">*/}
            {/*                        <i className="icon caret-right" />*/}
            {/*                        <span className="text">Collapsible container</span>*/}
            {/*                    </DropDownItem>*/}
            {/*                    {EmbedConfigs.map((embedConfig) => (*/}
            {/*                        <DropDownItem*/}
            {/*                            key={embedConfig.type}*/}
            {/*                            onClick={() => {*/}
            {/*                                activeEditor.dispatchCommand(*/}
            {/*                                    INSERT_EMBED_COMMAND,*/}
            {/*                                    embedConfig.type,*/}
            {/*                                );*/}
            {/*                            }}*/}
            {/*                            className="item">*/}
            {/*                            {embedConfig.icon}*/}
            {/*                            <span className="text">{embedConfig.contentName}</span>*/}
            {/*                        </DropDownItem>*/}
            {/*                    ))}*/}
            {/*                </DropDown>*/}
            {/*            </>*/}
            {/*        )}*/}
            {/*    </>*/}
            {/*)}*/}
            {/*<Divider />*/}
            {/*<ElementFormatDropdown*/}
            {/*    disabled={!isEditable}*/}
            {/*    value={elementFormat}*/}
            {/*    editor={activeEditor}*/}
            {/*    isRTL={isRTL}*/}
            {/*/>*/}

            {/*{modal}*/}
        </div>
    );
}