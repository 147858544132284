import React, { useState, useEffect, useMemo } from "react";
import {
    $getRoot,
    CLEAR_HISTORY_COMMAND
} from 'lexical';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import {
    CodeHighlightNode,
    CodeNode
} from '@lexical/code';
import { HashtagNode } from '@lexical/hashtag';
import {
    AutoLinkNode,
    LinkNode
} from '@lexical/link';
import {
    ListItemNode,
    ListNode
} from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { OverflowNode } from '@lexical/overflow';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import {
    HeadingNode,
    QuoteNode
} from '@lexical/rich-text';
import {
    TableCellNode,
    TableNode,
    TableRowNode
} from '@lexical/table';
import EditorTheme from './EditorTheme';
import Toolbar from './Toolbar';
import ComponentPicker from './ComponentPicker';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    $generateHtmlFromNodes,
    $generateNodesFromDOM
} from '@lexical/html';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { TreeView } from '@lexical/react/LexicalTreeView';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LinkPlugin as LexicalLinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import FloatingLinkEditorPlugin from './FloatingLinkEditorPlugin';
import { validateUrl } from './utils';

import './editor.css';

const EditorNodes = [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    HashtagNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    OverflowNode,
    HorizontalRuleNode,
    MarkNode
];

function OnChangePlugin({ onChange }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        return editor.registerUpdateListener(() => {
            editor.update(() => {
                onChange($generateHtmlFromNodes(editor, null));
            })
        });
    }, [editor, onChange]);
    return null;
}

function InitialValuePlugin({ initialHtml }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        //console.log('initialHtml & isEmpty', initialHtml);
        if (editor && initialHtml) {
            editor.update(() => {
                const root = $getRoot(); //console.log('root', root);
                if (root.getFirstChild() === null) {
                    const parser = new DOMParser();
                    const dom = parser.parseFromString(initialHtml, 'text/html');
                    const nodes = $generateNodesFromDOM(editor, dom);
                    const selection = root.select();
                    selection.removeText();
                    selection.insertNodes(nodes);
                    editor.dispatchCommand(CLEAR_HISTORY_COMMAND, null);
                }
            });
        }
    }, [editor, initialHtml]);
    return null;
}

function LinkPlugin({
    hasLinkAttributes = false,
}) {
    return (
        <LexicalLinkPlugin
            validateUrl={validateUrl}
            attributes={
                hasLinkAttributes
                    ? {
                        rel: 'noopener noreferrer',
                        target: '_blank',
                    }
                    : undefined
            }
        />
    );
}

function TreeViewPlugin() {
    const [editor] = useLexicalComposerContext();
    return (
        <TreeView
            viewClassName="tree-view-output"
            treeTypeButtonClassName="debug-treetype-button"
            timeTravelPanelClassName="debug-timetravel-panel"
            timeTravelButtonClassName="debug-timetravel-button"
            timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
            timeTravelPanelButtonClassName="debug-timetravel-panel-button"
            editor={editor}
        />
    );
}

export const CAN_USE_DOM =
    typeof window !== 'undefined' &&
    typeof window.document !== 'undefined' &&
    typeof window.document.createElement !== 'undefined';


export const Editor = ({
    defaultValue,
    onChange
}) => { 
    const [isLinkEditMode, setIsLinkEditMode] = useState(false);
    const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
    const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false);
    //console.log('Value from backend', defaultValue);
    function setHtml(html) {
        if (onChange) {
            onChange(html)
        }
    }
    const placeHolderText = 'Enter some text...';
    // Catch any errors that occur during Lexical updates and log them
    // or throw them as needed. If you don't throw them, Lexical will
    // try to recover gracefully without losing user data.
    function onError(error) {
        console.error(error);
    }

    const onRef = (_floatingAnchorElem) => {
        if (_floatingAnchorElem !== null) {
            setFloatingAnchorElem(_floatingAnchorElem);
        }
    };

    useEffect(() => {
        const updateViewPortWidth = () => {
            const isNextSmallWidthViewport =
                CAN_USE_DOM && window.matchMedia('(max-width: 1025px)').matches;

            if (isNextSmallWidthViewport !== isSmallWidthViewport) {
                setIsSmallWidthViewport(isNextSmallWidthViewport);
            }
        };
        updateViewPortWidth();
        window.addEventListener('resize', updateViewPortWidth);

        return () => {
            window.removeEventListener('resize', updateViewPortWidth);
        };
    }, [isSmallWidthViewport]);

    const CustomContent = useMemo(() => {
        return (
            <div className="editor-scroller">
                <div className="editor" ref={onRef}>
                    <ContentEditable className={'ContentEditable__root'}
                        aria-placeholder={placeHolderText}
                        placeholder={
                            <div className={'ContentEditable__placeholder'}>
                                {placeHolderText}
                            </div>
                        }
                    />
                </div>
            </div>
        )
    }, []);

    const initialConfig = {
        editorState: null,
        namespace: 'Custom Rich Text Editor',
        nodes: [...EditorNodes],
        theme: EditorTheme,
        onError,
    };
    
    return (
        <div className="custom-editor">
            <LexicalComposer initialConfig={initialConfig} >
                <Toolbar setIsLinkEditMode={setIsLinkEditMode} />
                <ComponentPicker />
                <div className="editor-container">
                    <RichTextPlugin
                        contentEditable={CustomContent}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <ListPlugin />
                    <CheckListPlugin />
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <ClearEditorPlugin />
                    <OnChangePlugin onChange={setHtml} />
                    <InitialValuePlugin initialHtml={defaultValue} />
                    <LinkPlugin hasLinkAttributes={true} />
                    {floatingAnchorElem &&
                        <FloatingLinkEditorPlugin
                            anchorElem={floatingAnchorElem}
                            isLinkEditMode={isLinkEditMode}
                            setIsLinkEditMode={setIsLinkEditMode} />
                    }
                    {/*<TreeViewPlugin />*/}
                </div>
            </LexicalComposer>
        </div>
    );
}