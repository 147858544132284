import React, { useEffect, useState, useRef } from 'react';
import { FormGroup, Label, Col, Input, Container, Row, ButtonToolbar, Button, Tooltip } from 'reactstrap';
import { CustomSelect } from '../../common/CustomSelect';
import { SelectWithStyles } from '../../common/CustomSelect';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ModalBase from '../../common/modals/ModalBase';
import { Section, Action, RevisionStatus, UnitTypeText, UnitType, Role } from '../../../helpers/enums';
import { useSelector } from 'react-redux';
import { UserInputModal } from './UserInputModal';
import DeleteModal from '../../common/modals/DeleteModal';
import SimpleCustomTable from '../../common/SimpleCustomTable';
import { extendedInputTypesWithDictionaries, validateFormulaFromServer, getUnitOptions } from '..';
import { UserInputColumns } from '../Columns';
import CustomTableWithEditableCellsAndSelection from '../../common/CustomTableWithEditableCellsAndSelection';
import { parseToNumber, getFactoryOptions, getSelectedOption } from '../../../helpers/commonHelpers';
import TextareaAutosize from 'react-textarea-autosize';
import EntityModalBase from './EntityModalBase';
import LabelWithTooltip from '../../common/LabelWithTooltip';
import { CustomSimpleEditor } from '../../common/CustomEditor';
import { CalculatedValueInput } from '../../common/CalculatedValueInput';

const schema = yup.object().shape({
    code: yup.string().label('Code').required().max(30),
    description: yup.string().label('Description').required().max(50),
    type: yup.object().shape({ value: yup.number().required('Select a Type') }).nullable().required('Select a Type'),
    factory: yup.object().shape({value:yup.number().required('Select a Factory')}).nullable().required('Select a Factory'),
    formula: yup.string().label('Formula')
        .when('inputDefinedLater', {
            is: false,
            then: yup.string().required()
        }),
    unit: yup.object().shape({ value: yup.number().nullable()}).nullable()
        .when('unitTypeId', {
            is: UnitType.UNIT_PREDEFINED,
            then: yup.object().shape({ value: yup.number().required('Select a Unit') }).nullable().required('Select a Unit')
        }),
    unitGroup: yup.object().shape({ value: yup.number().nullable()}).nullable()
        .when('unitTypeId', {
            is: UnitType.GROUP_PREDEFINED,
            then: yup.object().shape({ value: yup.number().required('Select a Unit group') }).nullable().required('Select a Unit group')
        }),
    internalNotes: yup.string().label('Internal notes').max(1000),
    comments: yup.string().label('Comments').max(1000)
        .when('notFirstVersion', {
            is: true,
            then: yup.string().required()
        })
});

const getUserInputs = (valueType, status) => {
    if (!valueType){
        return [];
    }

    if (!valueType.userInputs || valueType.userInputs.length === 0){
        return [];
    }

    if (status === Action.COPY) {
        var index = 1;
        return valueType.userInputs.map(input => ({
            ...input,
            uniqueId: index++,
            id: 0
        }));
    }

    return valueType.userInputs.map(input => ({
        ...input,
        uniqueId: input.id
    }));;
}

export default function ValueTypeModal({
    isOpen,
    toggle,
    status,
    selectedValueType,
    onEntityCreated,
    onEntityUpdated,
    msalInstance
}) {
    const currentUser = useSelector(state => state.application.user);
    const stateProperty = Section.WORKSPACE;
    const createNewRevision = status === Action.EDIT && selectedValueType && selectedValueType.id > 0 && 
        (selectedValueType.stageStatus === RevisionStatus.LATEST_REVISION || selectedValueType.prodStatus === RevisionStatus.LATEST_REVISION) 
        ? true : false;
    const notFirstVersion = status === Action.EDIT && selectedValueType.id > 0
        && (selectedValueType.stageStatus > RevisionStatus.NO_REVISION || selectedValueType.prodStatus > RevisionStatus.NO_REVISION);
    const tableInstance = useRef(null);
    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, getValues, setValue, clearErrors, trigger } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });
    const selectedStorage = useSelector(state => state[stateProperty].activeItem);
    const selectedFactoryIds = useSelector(state => state[stateProperty].selectedFactories);
    const factories = useSelector(state => state[stateProperty].factories);
    const selectedFactories = factories ? factories.filter(f => selectedFactoryIds.includes(f.id)) : [];
    const globalFactory = factories ? factories.filter(f => f.isGlobal)[0] : {};
    const elementReferences = selectedValueType && status !== Action.COPY ? selectedValueType.references : 0;
    const allReferences = selectedValueType && status !== Action.COPY ? selectedValueType.allReferences : 0;//console.log('allReferences', allReferences);
    const unitGroups = useSelector(state => state[stateProperty].storageUnitGroups);
    const units = useSelector(state => state[stateProperty].storageUnits);

    const factoryOptions = getFactoryOptions(selectedFactories, status === Action.VIEW ? Role.READER : Role.CONTRIBUTOR);
    const unitGroupOptions = unitGroups && unitGroups.length > 0 ? unitGroups.map(c => { return {value: c.id, label: c.name}}) : [];
    const unitOptions = getUnitOptions(units);
    
    const [userInputs, setUserInputs] = useState([]);
    const [selectedUserInputs, setSelectedUserInputs] = useState([]);
    const [uniqueId, setUniqueId] = useState(1);
    const [valueTypeFormula, setValueTypeFormula] = useState("");
    const [formulaHelpText, setFormulaHelpText] = useState("");
    const [formulaErrorText, setFormulaErrorText] = useState("");
    const [validateFormula, setValidateFormula] = useState(false);
    const [selectedType, setSelectedType] = useState(-1);
    const [calculatedValue, setCalculatedValue] = useState("");
    const [inputDefinedLater, setInputDefinedLater] = useState(false);
    //console.log('errors, values', errors, getValues());
    //console.log('selectedValueType', selectedValueType);
    const [modalStatus, setModalStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showTooltipUserInputs, setShowTooltipUserInputs] = useState(false);
    const [unitType, setUnitType] = useState(0);

    let defaultValues = { 
        id: 0, 
        code: "",
        description: "",
        type: [],
        factory: factoryOptions.length === 1 ? factoryOptions[0] : [],
        unitGroup: [],
        //formula:"",
        calculatedValue: "",
        unit: [],
        comments: "",
        internalNotes: "",
        notFirstVersion: notFirstVersion
    };
    
    const toggleModal = (status) => {
        setModalStatus(status);
        setShowModal(!showModal);
    }
    const toggleDeleteModal = () => {
        setShowDeleteModal(!showDeleteModal);
    }
    const toggleTooltipUserInputs = () => {
        //console.log('Show tooltip ' + !showTooltip);
        setShowTooltipUserInputs(!showTooltipUserInputs);
    }
    
    const userInputIsUpdated = (input) => {
        //console.log('Updated input', input);
        let newUserInputs = userInputs.map(i => ({...i}));
        // Are we updating user input that is already in db?
        let index = -1;
        if (input.id !== 0){
            index = newUserInputs.findIndex(i => i.id === input.id);
        }
        else {
            // Let's use internal id instead
            index = newUserInputs.findIndex(i => i.uniqueId === input.uniqueId);
        }
        if (index >= 0){
            let updatedInput = newUserInputs[index];
            updatedInput.code = input.code.replace(/\s/g, '_').toUpperCase();
            updatedInput.description = input.description;
            updatedInput.inputType = input.type;
            updatedInput.typeId = input.type.value;
            updatedInput.type = input.type.label;
            
            setUserInputs([...newUserInputs]);
            setSelectedUserInputs([updatedInput]);
        }
        checkFormula(valueTypeFormula);
    }

    const userInputsDeleted = () => {
        var deleteInputs = selectedUserInputs;//console.log('Deleted inputs', deleteInputs);
        if (deleteInputs.length > 0){
            // Empty all selected rows
            tableInstance?.current?.resetSelectedRows();
            setSelectedUserInputs([]);
            let newInputList = userInputs;
            deleteInputs.forEach(input => {
                var index = input.id === 0 && input.uniqueId ?
                    newInputList.findIndex(i => i.uniqueId === input.uniqueId) :
                    newInputList.findIndex(i => i.id === input.id); //console.log('deleted index', index);
                if (index >= 0){
                    newInputList.splice(index, 1);
                }
            });//console.log('newInputList', newInputList);
            setUserInputs([...userInputs]);
        }
        checkFormula(valueTypeFormula);
    }

    const userInputIsCreated = (input) => {
        //console.log('New input', input);
        setUniqueId(uniqueId + 1);
        setUserInputs([...userInputs, {
            id: input.id,
            uniqueId: uniqueId,
            code: input.code.replace(/\s/g, '_').toUpperCase(),
            description: input.description,
            inputType: input.type,
            typeId: input.type.value,
            type: input.type.label
        }]);
        clearErrors("userInputs");
    }

    const getData = (data) => {
        //console.log('data & userInputs', data, userInputs);
        if (!inputDefinedLater && (!data.formula || data.formula.length === 0)) {
            trigger("formula"); //console.log("Formula triggered!!!");
        }

        return {
            id: data.id,
            code: data.code,
            description: data.description,
            typeId: data.type.value,
            supportsAllFactories: globalFactory && data.factory.value === globalFactory.id,
            factoryId: data.factory.value,
            inputDefinedLater: data.inputDefinedLater,
            userInputs: inputDefinedLater || userInputs?.length === 0 ? []
                : userInputs.map((i, index) =>
                {
                    return { id: i.id, code: i.code, description: i.description, typeId: i.typeId, orderNumber: index + 1 }
                }),
            formula: valueTypeFormula,
            unitTypeId: data.unitTypeId,
            unitGroupId: data.unitTypeId !== UnitType.GROUP_PREDEFINED || !data.unitGroup ? null : data.unitGroup.value,
            unitId: data.unitTypeId !== UnitType.UNIT_PREDEFINED || !data.unit ? null : data.unit.value,
            storageId: data.storageId,
            comments: data.comments,
            internalNotes: data.internalNotes
        };
    }

    const checkFormula = async (formula, newUserInputs = null, newInputDefinedLater = null) => {
        //console.log('newUserInputs', newUserInputs);
        //console.log('formula', formula);
        setFormulaHelpText("");
        setFormulaErrorText("");
        const areInputsDefinedLater = newInputDefinedLater ?? inputDefinedLater;
        const inputs = areInputsDefinedLater ? [] : userInputs; //console.log('areInputsDefinedLater and inputs', areInputsDefinedLater, inputs)
        const selectedFactory = getValues('factory');
        const type = getValues("type")?.value ?? "";
        let result = await validateFormulaFromServer(
            formula,
            newUserInputs ?? inputs,
            selectedStorage,
            selectedFactory,
            "Formula",
            true,
            type,
            true,
            msalInstance,
            currentUser.authUser
        ); console.log('result', result);
        if (result?.value || result?.noValue || result?.value === 0){
            setCalculatedValue(result.value);
            setFormulaHelpText("Formula is valid!");
        }
        else{
            setCalculatedValue("");
            setValue('calculatedValue', "");
        }
        if (result.validationIsNull){
            setFormulaHelpText("Formula is valid. However the validation returned null!");
        }
        if (result.errorMsg && result.errorMsg.length > 0){
            setFormulaErrorText(result.errorMsg);
        }
    }
    
    const handleOnChange = (formula) => {
        //console.log('Handle formula change');
        setValidateFormula(true);
        setFormulaErrorText("");
        setFormulaHelpText("");
        setValueTypeFormula(formula);
        setValue('formula', formula);
        trigger("formula");
        trigger("type");
    }

    const handleTypeChange = (selectedOption) => {
        //console.log('selectedOption', selectedOption);
        defaultValues.type = selectedOption;
        setValue('type', selectedOption);
        setSelectedType(selectedOption?.value ?? -1);
        trigger("type");
        
        if (valueTypeFormula?.length > 0){
            checkFormula(valueTypeFormula);
        }
        else{
            setCalculatedValue("");
            setValue('calculatedValue', "");
        }
    }

    const handleFactoryChange = (selectedOption) => {
        //console.log('selectedOption', selectedOption);
        defaultValues.factory = selectedOption;
        setValue('factory', selectedOption);
        if (selectedOption){
            if (valueTypeFormula?.length > 0){
                checkFormula(valueTypeFormula);
            }
        }
        else{
            setCalculatedValue("");
            setValue('calculatedValue', "");
        }
    }

    const handleInputsDefinedChange = (e) => {
        const isChecked = e.target.checked; //console.log('has inputs defined changed', isChecked);
        setInputDefinedLater(isChecked);
        setValue('inputDefinedLater', isChecked);
        if (isChecked){
            setValueTypeFormula("");
            setValue('formula', "");
            setFormulaHelpText("");
            setFormulaErrorText("");
            setCalculatedValue("");
            clearErrors('formula');
        }
        else {
            if (valueTypeFormula?.length > 0){
                checkFormula(valueTypeFormula, null, isChecked);
            }
        }
        trigger("formula");
    }

    const updateCellData = async (rowIndex, columnId, newValue) => {
        // We also turn on the flag to not reset the page
        //console.log('newValue', newValue);console.log('columnId', columnId);console.log('rowIndex', rowIndex);
        clearErrors("calculatedValue");
        var newUserInputs = userInputs; //console.log('userInputs', userInputs);
        newUserInputs[rowIndex].value = newValue;
        setUserInputs(newUserInputs);
        if (valueTypeFormula){
            checkFormula(valueTypeFormula, newUserInputs);
        }
        else{
            setCalculatedValue("");
            setValue('calculatedValue', "");
        }
    }

    const handleUnitTypeChange = (e) => {
        if (e.target.value){
            const value = parseToNumber(e.target.value);
            setValue('unitTypeId', value);
            setUnitType(value);
        }
        else{
            setValue('unitTypeId', 0);
            setUnitType(0);
        }
        trigger('unit');
        trigger('unitGroup');
    }

    const handleCodeChange = (input) => {
        clearErrors('submitError');
        trigger('submitError');
        setValue('code', input.target.value);
        trigger('code');
    }

    const getUserInputTooltipText = (refs, allRefs) => {
        if (!refs && !allRefs) {
            return "";
        }

        if (!refs) {
            return "User inputs cannot be added/deleted since there are some old or deleted elements refering to the value type! You can only update the description for the inputs."
        }

        return "User inputs cannot be added/deleted since already " + refs + " element" + (refs > 1 ? "s are" : " is") + " refering to the value type! You can only update the description for the inputs."
    }

    useEffect(() => {//console.log('Validate formula')
        if (validateFormula && valueTypeFormula?.length > 0) {
            const timeoutId = setTimeout(() => checkFormula(valueTypeFormula), 1000);
            return () => clearTimeout(timeoutId);
        }
    }, [valueTypeFormula]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFormulaHelpText("");
        setFormulaErrorText("");
        if (selectedValueType && selectedValueType.id > 0) {
            //console.log('Set values!!!', selectedValueType);
            if (status !== Action.COPY) {
                setValue('id', selectedValueType.id);
            }
            setValue('code', selectedValueType.code);
            setValue('description', selectedValueType.description);
            setValue('type', { value: selectedValueType.typeId, label: selectedValueType.type });
            setSelectedType(selectedValueType.typeId);
            if (selectedValueType.supportsAllFactories && (!selectedValueType.supportedFactories || selectedValueType.supportedFactories.length === 0) && globalFactory) {
                // Set the global factory as selected one
                setValue('factory', getSelectedOption(factoryOptions, globalFactory.id));
            }
            else if (selectedValueType.supportedFactories && selectedValueType.supportedFactories.length > 0) {
                setValue('factory', getSelectedOption(factoryOptions, selectedValueType.supportedFactories[0].id));
            }
            var newInputs = getUserInputs(selectedValueType, status);
            if (newInputs?.length > 0) {
                var maxUniqueId = Math.max.apply(null, newInputs.map(i => i.uniqueId));
                if (maxUniqueId > 0) {
                    setUniqueId(maxUniqueId + 1);
                }
            }
            setUserInputs(newInputs); //console.log('newInputs', newInputs);
            setValueTypeFormula(selectedValueType.formula);
            setValue('formula', selectedValueType.formula);
            if (selectedValueType.formula) {
                checkFormula(selectedValueType.formula, newInputs);
            }
            setInputDefinedLater(selectedValueType.inputDefinedLater);
            setValue('inputDefinedLater', selectedValueType.inputDefinedLater);
            setUnitType(selectedValueType.unitTypeId);
            setValue('unitTypeId', selectedValueType.unitTypeId);
            setValue('unitGroup', selectedValueType.unitGroupId && selectedValueType.unitGroupId > 0 ?
                getSelectedOption(unitGroupOptions, selectedValueType.unitGroupId) : []);
            setValue('unit', selectedValueType.unitId && selectedValueType.unitId > 0 ?
                getSelectedOption(unitOptions, selectedValueType.unitId) : []);
            setValue('internalNotes', selectedValueType.internalNotes ?? "");
            if (!createNewRevision && status !== Action.COPY) {
                setValue('comments', selectedValueType.comments ?? ""); // If new revision is going to be created the comments field should be left empty!
            }
            else {
                setValue('comments', "");
            }
            setValue('notFirstVersion', notFirstVersion);
            trigger();
        }
        else {
            setValue('id', 0);
            setValue('code', "");
            setValue('description', "");
            setValue('type', []);
            setValue('factory', factoryOptions.length === 1 ? factoryOptions[0] : []);
            setUserInputs([]);
            setSelectedUserInputs([]);
            setValueTypeFormula("");
            setCalculatedValue("");
            setValue('calculatedValue', "");
            setUnitType(UnitType.NONE);
            setValue('unitGroup', []);
            setValue('unit', []);
            setValue('internalNotes', "");
            setValue('comments', "");
            setValue('notFirstVersion', notFirstVersion);
        }
    }, [selectedValueType, status, globalFactory, setValue, selectedFactoryIds]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!status){
        return null;
    }

    if (status === Action.EDIT && selectedValueType && selectedValueType.isGlobal){
        return(
            <ModalBase
                status={status}
                isOpen={isOpen}
                toggle={toggle}
                title="value type"
            >
                <p className="text-danger">Sorry you cannot edit Default value type {selectedValueType.code}!</p>
            </ModalBase>
        )
    }

    return (
        <React.Fragment>
            <EntityModalBase
                isOpen={isOpen}
                toggle={toggle}
                status={status}
                className="modal-lg"
                titleBase="value type"
                baseUrl="valuetype"
                getData={getData}
                createNewRevision={createNewRevision}
                formIsValid={isValid}
                errors={errors}
                setError={setError}
                reset={reset}
                handleSubmit={handleSubmit}
                entityIsCreated={onEntityCreated}
                entityIsUpdated={onEntityUpdated}
                msalInstance={msalInstance}
            >
                <Controller name="id"
                    control={control}
                    defaultValue={defaultValues.id}
                    render={({ field }) =>
                        <Input {...field} type="hidden" />
                    }
                />
                <Controller name="notFirstVersion" control={control} defaultValue={defaultValues.notFirstVersion}
                    render={({ field }) => <Input {...field} type="hidden" />}
                />
                <div className="border-bottom mb-2">
                    <h4>Supported factory</h4>
                </div>
                <FormGroup row className="required form-group">
                    <Label for="factory" sm={3}>Factory</Label>
                    <Col sm={9}>
                        <Controller name="factory"
                            control={control}
                            defaultValue={defaultValues.factory}
                            render={({ field }) =>
                                <SelectWithStyles {...field}
                                    options={factoryOptions}
                                    onChange={handleFactoryChange}
                                    isDisabled={allReferences > 0 || status === Action.VIEW} />
                        }
                        />
                        {errors.factory && <p className="text-danger">{errors.factory.message}</p>}
                    </Col>
                </FormGroup>
                <div className="border-bottom mb-2 mt-4">
                    <h4>General</h4>
                </div>
                <FormGroup row className="required form-group">
                    <Label for="code" sm={3}>Value code</Label>
                    <Col sm={9}>
                        <Controller name="code"
                            control={control}
                            defaultValue={defaultValues.code}
                            render={({ field }) =>
                                <Input
                                    {...field}
                                    type="text"
                                    onChange={handleCodeChange}
                                />
                            }
                        />
                        {errors.code && <p className="text-danger">{errors.code.message}</p>}
                    </Col>
                </FormGroup>
                <FormGroup row className="required form-group">
                    <Label for="code" sm={3}>Description</Label>
                    <Col sm={9}>
                        <Controller name="description"
                            control={control}
                            defaultValue={defaultValues.description}
                            render={({ field }) =>
                                <Input {...field} type="text" />
                            }
                        />
                        {errors.description && <p className="text-danger">{errors.description.message}</p>}
                    </Col>
                </FormGroup>
                <FormGroup row className="required form-group">
                    <Label for="type" sm={3}>Type</Label>
                    <Col sm={5} md={5}>
                        <Controller name="type"
                            control={control}
                            defaultValue={defaultValues.type}
                            render={({ field }) => 
                                <CustomSelect {...field}
                                    options={extendedInputTypesWithDictionaries()}
                                    onChange={handleTypeChange}
                                    isDisabled={status === Action.VIEW}
                                />
                            }
                        />
                        {errors.type && <p className="text-danger">{errors.type.message}</p>}
                        {errors.type && errors.type.value && <p className="text-danger">{errors.type.value.message}</p>}
                    </Col>
                </FormGroup>
                <FormGroup row className="form-group">
                    <LabelWithTooltip
                        id="tooltipFreeForm"
                        labelFor="inputDefinedLater"
                        tooltipInfoText="Formula of a free-form value type is set by the user when an element using this value type is created or modified."
                    >Free-form</LabelWithTooltip>
                    <Col sm={9}>
                        <Controller name="inputDefinedLater"
                            control={control}
                            defaultValue={inputDefinedLater}
                            render={({ field }) =>
                                <Input {...field}
                                    type="checkbox"
                                    onChange={handleInputsDefinedChange}
                                    checked={inputDefinedLater}
                                    disabled={allReferences > 0} />
                            }
                        />
                    </Col>
                </FormGroup>
                { !inputDefinedLater &&
                    <>
                        <Container fluid={true} className="mt-4">
                            <Row className="border-bottom mb-2">
                                <Col sm={3} className="ps-0">
                                    <h4 className="mt-2">Named input
                                    {allReferences > 0 && 
                                        <span className="tooltipInfo ms-1" id="tooltipUserInputs">
                                            <img src="./icons/access_locked.svg" className="icon icon-modal ms-1 me-1" alt="" />
                                            <Tooltip placement="right" isOpen={showTooltipUserInputs} target="tooltipUserInputs" toggle={toggleTooltipUserInputs}>
                                                {getUserInputTooltipText(elementReferences, allReferences)}
                                            </Tooltip>
                                        </span>}
                                    </h4>
                                </Col>
                                <Col sm={9}>
                                    <ButtonToolbar>
                                        <Button color="primary"
                                            size="sm"
                                            className="me-1"
                                            onClick={() => toggleModal(Action.CREATE)}
                                            disabled={allReferences > 0 || selectedUserInputs.length > 0}>
                                            Add
                                        </Button>
                                        <Button color="primary"
                                            size="sm"
                                            className="me-1"
                                            onClick={() => toggleModal(Action.EDIT)}
                                            disabled={selectedUserInputs.length !== 1}>
                                            Edit
                                        </Button>
                                        <Button color="danger"
                                            size="sm"
                                            className="me-1"
                                            onClick={() => toggleDeleteModal()}
                                            disabled={selectedUserInputs.length === 0 || allReferences > 0}>
                                            Delete
                                        </Button>
                                    </ButtonToolbar>
                                </Col>
                            </Row>
                        </Container>
                        <CustomTableWithEditableCellsAndSelection
                            tableColumns={UserInputColumns('Test input')}
                            tableData={userInputs}
                            onSelectedChanged={setSelectedUserInputs}
                            updateCellData={updateCellData}
                            reOrder={setUserInputs}
                            ref={tableInstance}
                        />
                        {errors.userInputs && <p className="text-danger">{errors.userInputs.message}</p>}
                        <Container fluid={true} className="mt-0">
                            <Row>
                                <Col className="fst-italic font-size-small">
                                    Use syntax <mark>User(&quot;CODE&quot;)</mark> to refer to named inputs.
                                </Col>
                            </Row>
                        </Container>
                    </>
                }
                <div className="border-bottom mb-2 mt-4">
                    <h4>Value output</h4>
                </div>
                { !inputDefinedLater &&
                    <>
                    <FormGroup row className="required form-group">
                        <LabelWithTooltip
                            id="tooltipFormula"
                            labelFor="formula"
                            iconUrl="./icons/lightning.svg"
                            tooltipText="Scripting functionality enabled."
                        >Formula</LabelWithTooltip>
                            <Col sm={9}>
                                <div className={status === Action.VIEW ? "container-editor form-control disabled" : "container-editor form-control"}>
                                    <Controller
                                        name="formula"
                                        control={control}
                                    defaultValue={valueTypeFormula}
                                        render={({ field }) =>
                                            <CustomSimpleEditor  {...field}
                                                value={valueTypeFormula}
                                                onChange={valueTypeFormula => handleOnChange(valueTypeFormula)}
                                                disabled={status === Action.VIEW}
                                            />
                                        }
                                    />
                                </div>
                                {formulaHelpText && <p className="text-primary">{formulaHelpText}</p>}
                                {formulaErrorText && <p className="text-danger">{formulaErrorText}</p>}
                                {errors.formula && <p className="text-danger">{errors.formula.message}</p>}
                            </Col>
                        </FormGroup>
                        <FormGroup row className="form-group">
                            <Label for="calculatedValue" sm={3}>Test output</Label>
                            <Col sm={9}>
                                <Controller name="calculatedValue"
                                    control={control}
                                    defaultValue={calculatedValue}
                                    render={({ field }) =>
                                        <CalculatedValueInput {...field} type={selectedType} value={calculatedValue} />
                                    }
                                />
                                {errors.calculatedValue && <p className="text-danger">{errors.calculatedValue.message}</p>}
                            </Col>
                        </FormGroup>
                    </>
                }
                <FormGroup tag="fieldset" row className="form-group">
                    <Label for="unitTypeId" sm={3}>Unit type</Label>
                    <Col sm={9}>
                        <Controller name="unitTypeId"
                            control={control}
                            defaultValue={unitType}
                            render={({ field }) => 
                            <>
                                <FormGroup check className="mt-2 form-group">
                                    <Label check>
                                        <Input {...field} type="radio" name="unitTypeId" value={UnitType.NONE} checked={unitType === UnitType.NONE}
                                            onChange={handleUnitTypeChange}
                                        />
                                        <div className='ms-2'>{UnitTypeText.NONE}</div>
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="mt-2 form-group">
                                    <Label check>
                                        <Input {...field} type="radio" name="unitTypeId" value={UnitType.OPTIONAL} checked={unitType === UnitType.OPTIONAL}
                                            onChange={handleUnitTypeChange}
                                        />
                                        <div className='ms-2'>{UnitTypeText.OPTIONAL}</div>
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="mt-2 form-group">
                                    <Label check>
                                        <Input {...field} type="radio" name="unitTypeId" value={UnitType.NOT_PREDEFINED} checked={unitType === UnitType.NOT_PREDEFINED}
                                            onChange={handleUnitTypeChange}
                                        />
                                        <div className='ms-2'>Required, {UnitTypeText.NOT_PREDEFINED.toLowerCase()}</div>
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="mt-2 form-group">
                                    <Label check>
                                        <Input {...field} type="radio" name="unitTypeId" value={UnitType.GROUP_PREDEFINED} checked={unitType === UnitType.GROUP_PREDEFINED}
                                            onChange={handleUnitTypeChange}
                                        />
                                        <div className='ms-2'>Required, {UnitTypeText.GROUP_PREDEFINED.toLowerCase()}</div>
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="mt-2 form-group">
                                    <Label check>
                                        <Input {...field} type="radio" name="unitTypeId" value={UnitType.UNIT_PREDEFINED} checked={unitType === UnitType.UNIT_PREDEFINED}
                                            onChange={handleUnitTypeChange}
                                        />
                                        <div className='ms-2'>Required, {UnitTypeText.UNIT_PREDEFINED.toLowerCase()}</div>
                                    </Label>
                                </FormGroup>
                            </>
                            }
                        />
                    </Col>
                </FormGroup>
                { unitType === UnitType.UNIT_PREDEFINED && 
                    <FormGroup row className="required form-group">
                        <Label for="unit" sm={3}>Unit</Label>
                        <Col sm={9}>
                            <Controller name="unit" control={control} defaultValue={defaultValues.unit}
                                render={({field}) => <SelectWithStyles {...field} options={unitOptions} isClearable/>}
                            />
                            {errors.unit && <p className="text-danger">{errors.unit.message}</p>}
                        </Col>
                    </FormGroup>
                }
                { unitType === UnitType.GROUP_PREDEFINED &&
                    <FormGroup row className="required form-group">
                        <Label for="unitGroup" sm={3}>Unit group</Label>
                        <Col sm={9}>
                            <Controller name="unitGroup" control={control} defaultValue={defaultValues.unitGroup}
                                render={({field}) => <CustomSelect {...field} options={unitGroupOptions} isClearable/>}
                            />
                            {errors.unitGroup && <p className="text-danger">{errors.unitGroup.message}</p>}
                        </Col>
                    </FormGroup>
                }
                <div className="border-bottom mb-2 mt-4">
                    <h4>Additional info</h4>
                </div>
                <FormGroup row className="form-group">
                    <Label for="internalNotes" sm={3}>Internal notes</Label>
                    <Col sm={9}>
                        <Controller name="internalNotes" control={control} defaultValue={defaultValues.internalNotes}
                            render={({ field }) =>
                                <TextareaAutosize {...field}
                                    maxRows={10}
                                    minRows={2}
                                    className="form-control"
                                />}
                        />
                        {errors.internalNotes && <p className="text-danger">{errors.internalNotes.message}</p>}
                    </Col>
                </FormGroup>
                <div className="border-bottom mb-2 mt-4">
                    <h4>Revision info</h4>
                </div>
                <FormGroup row className={notFirstVersion ? "required form-group" : "form-group"}>
                    <Label for="comments" sm={3}>Comments</Label>
                    <Col sm={9}>
                        <Controller name="comments" control={control} defaultValue={defaultValues.comments}
                            render={({ field }) => <TextareaAutosize {...field} maxRows={10} minRows={2} className="form-control" />}
                        />
                        {errors.comments && <p className="text-danger">{errors.comments.message}</p>}
                    </Col>
                </FormGroup>
            </EntityModalBase>
            <UserInputModal
                isOpen={showModal}
                toggle={toggleModal}
                status={modalStatus}
                existingInputs={userInputs}
                selectedUserInput={selectedUserInputs.length > 0 ? selectedUserInputs[0] : {}}
                onUserInputCreated={userInputIsCreated}
                onUserInputUpdated={userInputIsUpdated}
                references={allReferences} />
            <DeleteModal
                isOpen={showDeleteModal}
                toggle={toggleDeleteModal}
                entityProperties={{
                    baseUrl: 'userInput/',
                    single: 'user input',
                    plural: 'user inputs'
                }}
                deleteIds={selectedUserInputs.length > 0 ? selectedUserInputs.map(i => i.id) : []}
                storageId={selectedStorage ? selectedStorage.id : 0}
                onDeleted={userInputsDeleted}
                msalInstance={msalInstance}
                user={currentUser.authUser}
            >
                <p>Are you sure you want to delete user inputs</p>
                <SimpleCustomTable
                    tableColumns={UserInputColumns()}
                    tableData={selectedUserInputs}
                    hiddenColumns={["value"]}
                />
            </DeleteModal>
        </React.Fragment>
    )
}